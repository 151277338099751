<template>
    <div class="zykt-main p-1 h-100 box-border">
        <el-card class="zy-card ova yes-head h-100">
            <el-card class="zy-card no-head mb-1">
                <el-row :gutter="10" class="d-flex jc-around">
                    <el-col
                        :span="4"
                        v-for="(item, index) in navList"
                        :key="index"
                    >
                        <div class="d-flex ai-center">
                            <i
                                :class="['iconfont', item.icon]"
                                :style="{ color: item.iconColor }"
                                class="font-icon"
                            ></i>
                            <div class="ml-1">
                                <span class="font-18">{{ item.text }}</span>
                                <br />
                                <span class=" ml-1 font-18 fw-600">{{
                                    item.num
                                }}</span>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-card>

            <el-card class="zy-card opt-head ova-head">
                <div class="d-flex ai-center jc-between mb-1">
                    <div class="d-flex">
                        <zy-cascader
                            class="mr-1"
                            ref="zyCascader"
                            expand-trigger="click"
                            :cascaderData="cascaderData"
                            lazy
                            @lazy-load="handleCascaderLazyLoad"
                            @change="handleCascaderChange"
                        ></zy-cascader>
                        <el-input
                            class="mr-1"
                            v-model="room"
                            placeholder="户号"
                            style="width:120px"
                        ></el-input>
                        <el-select
                            class="selectInp mr-1"
                            v-model="paySt"
                            placeholder="请选择缴费状态"
                        >
                            <el-option
                                v-for="item in payStOpt"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                        <el-button type="primary" @click="searchTabData">
                            搜索
                        </el-button>
                        <el-button type="primary" @click="addData">
                            新增
                        </el-button>
                    </div>
                    <div class="mr-2">
                        <span style="color:red">小提示：</span>
                        <span class="font-14 mr-1">高档：</span>
                        <i
                            class="iconfont icon-gao font-24 mr-1"
                            style="color:green"
                        >
                        </i>
                        <span class="font-14 mr-1">中档：</span>
                        <i
                            class="iconfont icon-zhong font-24 mr-1"
                            style="color:#3CB371"
                        >
                        </i>
                        <span class="font-14 mr-1">低档：</span>
                        <i
                            class="iconfont icon-di font-24 mr-1"
                            style="color:#89BD3E"
                        >
                        </i>
                        <span class="font-14 mr-1">关闭：</span>
                        <i class="iconfont icon-didang-hui font-24 "> </i>
                    </div>
                </div>
                <zy-table :tbHead="tbHead" :tbBody="tbBody" highlightCurrentRow>
                    <el-table-column type="expand" slot="column-before">
                        <template slot-scope="props">
                            <el-form
                                label-position="left"
                                inline
                                class="demo-table-expand"
                                label-width="100px"
                            >
                                <el-form-item label="NB序列号：">
                                    <span>{{ props.row.deviceId }}</span>
                                </el-form-item>
                                <el-form-item label="SIM序列号：">
                                    <span>{{ props.row.sim }}</span>
                                </el-form-item>
                                <el-form-item label="设备号：">
                                    <span>{{ props.row.fpId }}</span>
                                </el-form-item>
                                <el-form-item label="SN号：">
                                    <span>{{ props.row.snId }}</span>
                                </el-form-item>
                            </el-form>
                        </template>
                    </el-table-column>

                    <template slot="realtime-gear" slot-scope="scope">
                        <span :type="scope.row.dangWei" class="ta-cente">
                            <i
                                v-if="scope.row.dangWei === '高档'"
                                class="iconfont icon-gao font-24"
                                style="color:green"
                            >
                            </i>
                            <i
                                v-else-if="scope.row.dangWei === '中档'"
                                class="iconfont icon-zhong font-24"
                                style="color:#44AE4C"
                            >
                            </i>
                            <i
                                v-else-if="scope.row.dangWei === '低档'"
                                class="iconfont icon-di font-24"
                                style="color:#89BD3E"
                            >
                            </i>
                            <i v-else class="iconfont icon-didang-hui font-24">
                            </i>
                            <!-- <span>{{ scope.row.dangWei }}</span> -->
                        </span>
                    </template>
                </zy-table>

                <zy-page
                    class="search-site-page mt-1"
                    @current-change="handlePageCurrentChange"
                    @size-change="handlePageSizeChange"
                    :pageModel="pageModel"
                    :total="total"
                    :pageSizes="[15, 20, 50, 300]"
                ></zy-page>
            </el-card>
        </el-card>

        <!-- 编辑 -->
        <el-dialog
            title="编辑"
            :visible.sync="addUserDialogVisible"
            width="580px"
        >
            <el-form
                :model="addModel"
                class="edit-user-dialog"
                label-width="90px"
            >
                <div class="d-flex  ai-center">
                    <el-form-item label="小区：">
                        <el-input
                            placeholder="武陟懿品"
                            :disabled="true"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="楼栋：">
                        <el-input v-model="addModel.building"></el-input>
                    </el-form-item>
                </div>

                <div class="d-flex ai-center">
                    <el-form-item label="单元：">
                        <el-input v-model="addModel.unit"></el-input>
                    </el-form-item>

                    <el-form-item label="户号：">
                        <el-input v-model="addModel.room"></el-input>
                    </el-form-item>
                </div>

                <div class="d-flex jc-between ai-center">
                    <el-form-item label="设备号：">
                        <el-input v-model="addModel.deviceId"></el-input>
                    </el-form-item>
                    <el-form-item label="金额：">
                        <el-input v-model="addModel.money"></el-input>
                    </el-form-item>
                </div>

                <!-- <el-form-item label="备注：">
                    <el-input
                        resize="none"
                        type="textarea"
                        :rows="2"
                        v-model="addModel.remark"
                    ></el-input>
                </el-form-item> -->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addUserDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="handleEditUserEnterClick"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import ZyTable from '@/components/ZyTable/index'
import ZyPage from '@/mixins/ZyPage'
import Excel from '@/mixins/excel'
import cascader from '@/mixins/select'
export default {
    components: { ZyTable },
    mixins: [ZyPage, cascader, Excel],
    data() {
        return {
            navList: [
                {
                    icon: 'icon-shebeizaixian',
                    text: '当前设备在线',
                    num: '',
                    flag: 'zhongDuanZaiXian'
                },
                {
                    icon: 'icon-xufei',
                    iconColor: '#3FDF59',
                    text: '缴费用户',
                    num: '',
                    flag: 'havePay'
                },
                {
                    icon: 'icon-ic_user_zx',
                    text: '当前使用户数',
                    num: '',
                    flag: 'nowUse'
                },
                {
                    icon: 'icon-zonglan',
                    text: '预计结算当量',
                    num: '',
                    flag: 'estimateDl'
                }
            ],

            room: '',
            // 计费状态
            paySt: '',
            payStOpt: [
                {
                    value: '全部',
                    label: '全部'
                },
                {
                    value: '已缴费',
                    label: '已缴费'
                },
                {
                    value: '未缴费',
                    label: '未缴费'
                },
                {
                    value: '欠费',
                    label: '欠费'
                }
            ],
            // 表头
            tbHead: [
                {
                    prop: 'acname',
                    label: '小区'
                },
                {
                    prop: 'building',
                    label: '楼栋',
                    width: 50
                },
                {
                    prop: 'unit',
                    label: '单元',
                    width: 50
                },
                {
                    prop: 'room',
                    label: '户号',
                    width: 60
                },
                {
                    prop: 'totalMoney',
                    label: '余额'
                },
                // {
                //     prop: '',
                //     label: '缴费',
                //     width: 50
                // },
                {
                    prop: 'jifeiStatus',
                    label: '计费状态'
                },
                {
                    prop: 'status',
                    label: '运行状态'
                },

                {
                    prop: 'dangWei',
                    label: '当前档位',
                    slot: 'realtime-gear'
                },

                {
                    prop: 'high',
                    label: '高档时间(分)',
                    width: 110
                },
                {
                    prop: 'mid',
                    label: '中档时间(分)',
                    width: 110
                },
                {
                    prop: 'low',
                    label: '低档时间(分)',
                    width: 110
                },
                {
                    prop: 'totalDangLiang',
                    label: '总当量'
                },
                {
                    prop: 'zhouQi',
                    label: '周期(分)',
                    width: 80
                },
                {
                    prop: 'recordTime',
                    label: '上传时间',
                    sortable: true,
                    width: 170
                }
                // {
                //     prop: 'deviceId',
                //     label: 'NB序列号',
                //     width: 100,
                //     overflow: true
                // },
                // {
                //     prop: '',
                //     label: 'SIM序列号',
                //     width: 110
                // },
                // {
                //     prop: 'fpId',
                //     label: '设备号',
                //     width: 100
                // },
                // {
                //     prop: '',
                //     label: '室外温度',
                //     width: 110
                // }
            ],

            // 内容
            tbBody: [],

            addModel: {},
            addUserDialogVisible: false
        }
    },
    created() {
        this.getTabData()
        this.getCard()
    },
    methods: {
        async getCard() {
            const res = await this.$api.survey.getCard()
            this.navList.forEach(item => {
                const keyItem = res.data[item.flag]
                console.log('000', keyItem)
                item.num = keyItem
            })
        },
        // 获取表格数据
        async getTabData() {
            const pageNum = this.pageModel.pagenum.toString()
            const pageSize = this.pageModel.pagesize.toString()

            const res = await this.$api.selectData.getTabData(pageNum, pageSize)
            this.tbBody = res.data.list
            this.total = res.data.total
        },

        // 搜索
        async searchTabData() {
            if (this.cascaderModelArr.length < 4) {
                this.cascaderModel.room = ''
            }
            const par = this.cascaderModel
            par['room'] = this.room
            par['qianFeiStatus'] = this.paySt
            const res = await this.$api.selectData.searchTab(par)
            this.tbBody = res.data.list
            this.total = res.data.total
        },

        // 分页回调
        getPageList() {
            return this.getTabData()
        },

        // 新增
        addData() {
            this.addUserDialogVisible = true
        },

        // 确定编辑用户
        async handleEditUserEnterClick() {
            const res = await this.$api.realtime.addUser(this.addModel)
            if (res.code != 200) {
                this.$message.error('注册用户失败！')
            } else {
                this.$message.success(res.data)
            }

            // 关闭弹窗
            this.addUserDialogVisible = false

            // 重新获取表格
            this.getTabData()
        }
    }
}
</script>

<style lang="scss" scoped>
.font-icon {
    font-size: 40px;
}
</style>
